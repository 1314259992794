import { useState } from 'react';
import logo from './uo_logo.png';
import { useNavigate } from 'react-router-dom';
import './profile.css'
import axios from 'axios';
import bars from './bars.png';
import x from './x.png';

function Profile({ auth, setAuth }) {
    const [category, setCategory] = useState(0);
    const [editing, setEditing] = useState(false);
    const navigate = useNavigate();
    const packages = ["Basic Wash","Essentials", "Silver", "Gold", "Platinum"];
    const [loading, setLoading] = useState(false);
    const [navActive, setNavActive] = useState(false);

    const galleryImages = () => {
        const imageUrls = [];

        for (const appointment of auth.appointments) {
            for (const imageUrl of appointment.imageurls) {
                imageUrls.push(imageUrl);
            }
        }

        return imageUrls.map((image) => {
            return (
                <div className='gallery-card'>
                    <img onClick={() => window.open(image, '_blank')} src={image} alt='Gallery' />
                    <p>Click to View</p>
                </div>
            );
        })
    }

    const handleLogout = () => {
        setAuth(null);
        localStorage.removeItem('uo_user');
        navigate('/');
    }

    const handleUpdate = async (e) => {
        e.preventDefault();

        let updatePass = false;

        if (e.target.elements.password.value !== e.target.elements.confirm_password.value) {
            alert("Passwords do not match");
            return;
        } else if (e.target.elements.password.value !== "" && e.target.elements.confirm_password.value !== "") {
            updatePass = true;
        }

        const user_info = JSON.stringify({
            id: auth.id,
            name: e.target.elements.name.value === "" ? auth.name : e.target.elements.name.value,
            phone_num: e.target.elements.phone_num.value === "" ? auth.phone_num : e.target.elements.phone_num.value,
            address: e.target.elements.address.value === "" ? auth.address : e.target.elements.address.value,
            city: e.target.elements.city.value === "" ? auth.city : e.target.elements.city.value,
            postal_code: e.target.elements.postal_code.value === "" ? auth.postal_code : e.target.elements.postal_code.value,
            password: updatePass ? e.target.elements.password.value : null
        })

        setLoading(true);
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/auth/update', { user_info });

            if (response.data.status.email) {
                setAuth(response.data.status);
                localStorage.setItem("uo_user", JSON.stringify(response.data.status));
            } else {
                alert(response.data.status);
            }
        } catch (error) {
            console.error(error);
        }
        setLoading(false);

        setEditing(false);
        e.target.reset();
    }

    const handleCancellation = async (appointmentId) => {

        if (!window.confirm('Are you sure you want to cancel this appointment?')) {
            return
        }

        setLoading(true);
        try {
            const response = await axios.delete(process.env.REACT_APP_API_URL + `/bookings/${appointmentId}/${auth.id}`);

            if (response.data.status.email) {
                setAuth(response.data.status);
                localStorage.setItem("uo_user", JSON.stringify(response.data.status));
                alert('Appointment cancelled successfully');
            } else {
                alert(response.data.status);
            }
        } catch (error) {
            console.error(error);
            alert('Failed to cancel appointment');
        }
        setLoading(false);
    };

    const handleProfileView = () => {
        if (category === 0 && editing === false) {
            return (
                <div className='profile-details-cont'>
                    <p>Name: {auth?.name}</p>
                    <p>Email: {auth?.email}</p>
                    <p>Phone Number: {auth?.phone_num}</p>
                    <p>Address: {auth?.address}</p>
                    <p>City: {auth?.city}</p>
                    <p>Postal Code: {auth?.postal_code}</p>
                    <p>Password: * * * * * * * * * *</p>

                    <button onClick={() => setEditing(true)}>Edit Account Details</button>
                </div>
            );
        } else if (category === 0 && editing) {
            return (
                <form onSubmit={handleUpdate} className='details-form'>
                    <div className='det-inp-cont'>
                        <label>Name:</label>
                        <input name='name' type='text' />
                    </div>

                    {/* <div className='det-inp-cont'>
                        <label>Email:</label>
                        <input type='text' />
                    </div> */}

                    <div className='det-inp-cont'>
                        <label>Phone Number:</label>
                        <input name='phone_num' placeholder='123-456-789' type='text' />
                    </div>

                    <div className='det-inp-cont'>
                        <label>Address:</label>
                        <input name='address' placeholder='123 Park Lane' type='text' />
                    </div>

                    <div className='det-inp-cont'>
                        <label>City:</label>
                        <input name='city' placeholder='e.g. Toronto' type='text' />
                    </div>

                    <div className='det-inp-cont'>
                        <label>Postal Code:</label>
                        <input name='postal_code' placeholder='e.g. M1M 1M1' type='text' />
                    </div>

                    <div className='det-inp-cont'>
                        <label>Password:</label>
                        <input name='password' type='text' />
                    </div>

                    <div className='det-inp-cont'>
                        <label>Confirm Password:</label>
                        <input name='confirm_password' type='text' />
                    </div>

                    <div className='det-btn-cont'>
                        <button onClick={() => setEditing(false)} type='button'>Cancel</button>
                        <button type='submit'>Submit</button>
                    </div>
                </form>
            );
        } else if (category === 1) {
            return (
                <div className={auth.appointments.length > 0 ? 'bookings-cont' : 'bookings-cont no-apts'}>
                    {auth.appointments.length > 0 ?
                        auth.appointments.map((booking, index) => {
                            return (
                                <div key={index} className='booking-card' onClick={booking.status === "upcoming" ? (() => handleCancellation(booking.id)):null }>
                                    <h3>{booking.vehicle_details}</h3>
                                    <p>{packages[booking.package_id - 1]}</p>
                                    <p>{booking.date}</p>
                                    {booking.status === "upcoming" ?
                                        <p style={{ color: 'red' }}>Cancel</p>
                                        :
                                        null
                                    }
                                </div>
                            );
                        })
                        : <h1 style={{ textAlign: 'center' }}>No Appointments</h1>
                    }
                </div>
            );
        } else if (category === 2) {
            return (
                <div className={galleryImages().length > 0 ? 'gallery-cont' : 'gallery-cont no-images'}>
                    {galleryImages().length > 0 ? galleryImages() : <h1 style={{ textAlign: 'center' }}>Images Not Yet Available</h1>}
                </div>
            );
        }
    }

    if (auth) {
        return (
            <div className='profile-page'>
                <div className='loader-cont' style={{ display: loading ? 'flex' : 'none' }}>
                    <h1>Loading...</h1>
                </div>
                <div className='nav-cont'>

                    <div className='nav-ls'>
                        <a href='/' className='nav-btn'>Home</a>
                        <a href='/#packages' className='nav-btn'>Pricing</a>
                    </div>

                    <div className='nav-ct'>
                        <img className='logo' src={logo} alt='Logo' />
                    </div>

                    <div className='nav-rs'>
                        <a href='mailto:ali@undergroundoasis.ca' className='nav-btn'>Contact Us</a>
                        <a onClick={handleLogout} className='nav-btn'>Log Out</a>
                    </div>

                </div>

                <div className={navActive ? 'mobile-nav active' : 'mobile-nav'}>
                    <img onClick={() => setNavActive(!navActive)} className='m-icn' src={navActive ? x : bars} alt='Menu' />
                    <div className='m-nav-ct'>
                        <img className='m-logo' src={logo} />
                    </div>
                    <div className='m-nav-opt'>
                        <a href='/' className='nav-btn'>Home</a>
                    </div>
                    <div className='m-nav-opt'>
                        <a href='/#packages' className='nav-btn'>Pricing</a>
                    </div>
                    <div className='m-nav-opt'>
                        <a href='mailto:ali@undergroundoasis.ca' className='nav-btn'>Contact Us</a>
                    </div>
                    <div className='m-nav-opt'>
                        <a onClick={handleLogout} className='nav-btn'>{auth ? 'View Profile' : 'Log In'}</a>
                    </div>
                </div>

                <div className='profile-header' style={{ filter: loading ? 'blur(5px)' : 'none' }}>
                    <h1>{auth.name !== "" ? "Welcome " + auth.name + "!" : "Welcome!"}</h1>
                </div>
                <div className='profile-body' style={{ filter: loading ? 'blur(5px)' : 'none' }}>
                    <div className='profile-content'>
                        <div className='profile-categories'>
                            <div onClick={() => setCategory(0)} className={category === 0 ? 'profile-category1 active' : 'profile-category1'}>
                                <h3>Details</h3>
                            </div>
                            <div onClick={() => setCategory(1)} className={category === 1 ? 'profile-category2 active' : 'profile-category2'}>
                                <h3>Bookings</h3>
                            </div>
                            <div onClick={() => setCategory(2)} className={category === 2 ? 'profile-category3 active' : 'profile-category3'}>
                                <h3>Gallery</h3>
                            </div>
                        </div>
                        <div className='profile-views'>
                            {handleProfileView()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100%', position: 'fixed', top: '0px', left: '0px', zIndex: '99' }}>
                <p style={{ fontSize: '3vw', marginBottom: '40px' }}>401: Unauthorized</p>
                <p style={{ fontSize: '2vw' }}>Click <a style={{ fontSize: '2vw', color: 'blue' }} href='/'>Here</a> To Login</p>
            </div>
        );
    }
}

export default Profile;

// {"address":null,"appointments":[],"city":null,"email":"ali@undergroundoasis.ca","id":1,"name":null,"phone_num":null,"postal_code":null}

