import axios from 'axios';
import { useState } from 'react';
import './admin.css';

function Admin() {
    const [apts, setApts] = useState([]);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(null);
    const [auth, setAuth] = useState({})

    //for deleting apt, we have to handle when we have user id AND when we dont
    //MAKE ANONYM BOOKING WORK ON FRONTEND

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/auth/login', {
                user_info: JSON.stringify({
                    email: e.target.elements.email.value,
                    password: e.target.elements.pass.value
                })
            });

            if (response.data.status.email) {
                setAuth(response.data.status);
                alert("Login Successful!")
            } else {
                alert(response.data.status);
            }
        } catch (error) {
            alert("Failed to login.");
            console.error(error);
        }
        setLoading(false);
        e.target.reset();
    }

    const handleUpdate = async (e, id) => {
        e.preventDefault();
        setUpdating(null);
        setLoading(true);

        try {
            const response = await axios.put(process.env.REACT_APP_API_URL + `/bookings/${id}`, {
                booking_info: JSON.stringify({
                    email: auth.email,
                    summary: e.target.elements.summary.value,
                    status: e.target.elements.status.value,
                    imageurls: e.target.elements.imageurls.value !== "" ? e.target.elements.imageurls.value.split(","): []
                })
            })

            alert(response.data.message);
            getApts();
        } catch (error) {
            console.error(error);
            alert('Failed to update');
        }

        setLoading(false);
        getApts();
    }

    const handleCancellation = async (appointmentId) => {

        if (!window.confirm('Are you sure you want to cancel this appointment?')) {
            return
        }

        setLoading(true);
        try {
            const response = await axios.delete(process.env.REACT_APP_API_URL + `/bookings/${appointmentId}`);
            alert(response.data.status);
        } catch (error) {
            console.error(error);
            alert('Failed to cancel appointment');
        }
        setLoading(false);
        getApts();
    };

    const banUser = async (id) => {
        setLoading(true);
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/auth/ban', {
                user_info: JSON.stringify({
                    email: auth.email,
                    id: id
                })
            });
            alert(response.data.message);
            getUsers();
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    }

    const getApts = async () => {
        setLoading(true);
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/auth/apts', {
                user_info: JSON.stringify({
                    email: auth.email,
                })
            });
            if (response.data.message) {
                console.log(response.data.message);
                return;
            } else {
                setApts(response.data);
            }
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    }

    const getUsers = async () => {
        setLoading(true);
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/auth/users', {
                user_info: JSON.stringify({
                    email: auth.email,
                })
            });
            if (response.data.message) {
                console.log(response.data.message);
                return;
            } else {
                setUsers(response.data);
            }
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    }

    if (auth.email === process.env.REACT_APP_ADMIN_EMAIL) {
        return (
            <div className='admin-page'>
                <div className='ad-users-cont'>
                    <div className='ad-users-header'>
                        <h1>Users</h1>
                    </div>
                    <div className='ad-users'>
                        {
                            users.map((user, index) => (
                                <div key={index} className='ad-user'>
                                    <p>ID: {user[0]}</p>
                                    <p>Email: {user[2]}</p>
                                    <p>Name: {user[1]}</p>
                                    <p>Phone #: {user[6]}</p>
                                    <p>Address: {user[5]}</p>
                                    <p>City: {user[3]}</p>
                                    <p>Postal Code: {user[4]}</p>
                                    <div className='ad-user-btns'>
                                        <button disabled={loading ? true : false} onClick={() => banUser(user[0])}>Ban User</button>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className='ad-users-btns'>
                        <button onClick={getUsers} disabled={loading ? true : false}>Get Users</button>
                    </div>
                </div>
                <div className='ad-apts-cont'>
                    <div className='ad-apts-header'>
                        <h1>Appointments</h1>
                    </div>
                    <div className='ad-apts'>
                        {
                            apts.map((apt, index) => (
                                <div key={index} className='ad-apt'>
                                    <p>ID: {apt.id}</p>
                                    <p>Name: {apt.name}</p>
                                    <p>Email: {apt.email}</p>
                                    <p>Phone #: {apt.phone_num}</p>
                                    <p>Address: {apt.address}</p>
                                    <p>City: {apt.city}</p>
                                    <p>Postal Code: {apt.postal_code}</p>
                                    <p>Time: {apt.time}</p>
                                    <p>Comments: {apt.comments}</p>
                                    <p>Package: {apt.package_id}</p>
                                    <p>Vehicle: {apt.vehicle_details}</p>
                                    {updating === index ?
                                        <form  className='ad-apt-form' onSubmit={e => handleUpdate(e, apt.id)}>
                                            <label>Status:</label>
                                            <select defaultValue={apt.status} name='status'>
                                                <option value='upcoming'>upcoming</option>
                                                <option value='completed'>completed</option>
                                            </select>
                                            <label>Images (comma separated):</label>
                                            <input name='imageurls' defaultValue={(apt.imageurls).join(',')} type='text' />
                                            <label>Summary:</label>
                                            <textarea defaultValue={apt.summary} name='summary' type='text' />
                                            <div style={{display: 'flex', justifyContent: 'space-evenly', marginTop: '10px'}}>
                                                <button type='button' onClick={() => setUpdating(null)}>Cancel</button>
                                                <button type='submit'>Submit</button>
                                            </div>
                                        </form>
                                        :
                                        <>
                                            <p>Status: {apt.status}</p>
                                            <p>Images: {(apt.imageurls).join(',')}</p>
                                            <p>Summary: {apt.summary}</p>
                                        </>
                                    }
                                    <div className='ad-apt-btns'>
                                        <button style={{visibility: updating === index ? 'hidden' : 'visible'}} disabled={loading ? true : false} onClick={() => handleCancellation(apt.id)}>Cancel Appointment</button>
                                        <button style={{visibility: updating === index ? 'hidden' : 'visible'}} disabled={loading ? true : false} onClick={() => setUpdating(index)}>Update Appointment</button> {/* disabled={loading ? true : false} */}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className='ad-apts-btns'>
                        <button onClick={getApts} disabled={loading ? true : false}>Get Appointments</button>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100%', position: 'fixed', top: '0px', left: '0px', zIndex: '99' }}>
                <form className='admin-login' onSubmit={handleLogin}>
                    <h1 style={{ textAlign: 'center' }}>Admin Login:</h1>
                    <input style={{ backgroundColor: 'black', color: 'white', border: '2px solid white', borderRadius: '10px', padding: '10px', fontSize: '20px' }} type='email' name='email' />
                    <input style={{ backgroundColor: 'black', color: 'white', border: '2px solid white', borderRadius: '10px', padding: '10px', fontSize: '20px' }} type='password' name='pass' />
                    <button disabled={loading ? true : false} type='submit'>Submit</button>
                </form>
            </div>
        )
    }
}

export default Admin;



// MAKE RESPONSIVE AND FIX GET APTS ERROR (setup google meets after)