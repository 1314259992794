import { useEffect, useState } from 'react';
import logo from './uo_logo.png';
import inspect from './inspect.png';
import intext from './interior&exterior.jpg';
import wash from './wash.jpg';
import value from "./value.png"
import "./landing.css"
import axios from 'axios';
import bars from './bars.png';
import x from './x.png';

function Landing({ auth, setAuth }) {
    const [booking, setBooking] = useState(false);
    const [authMode, setAuthMode] = useState("login");
    const [loading, setLoading] = useState(false);
    const [navActive, setNavActive] = useState(false);
    const hours = ["8am", "9am", "10am", "11am", "12pm", "1pm", "2pm", "3pm", "4pm", "5pm", "6pm", "7pm"];
    const hourNums = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];
    const [currHours, setCurrHours] = useState(["8am", "9am", "10am", "11am", "12pm", "1pm", "2pm", "3pm", "4pm", "5pm", "6pm", "7pm"]);
    const [time, setTime] = useState(null);
    const [date, setDate] = useState(null);
    const [dateTime, setDateTime] = useState(null);
    const [pkg, setPkg] = useState(1);
    const [times, setTimes] = useState(null);

    const getTimes = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/timings');

            if (!response.data.message) {
                setTimes(response.data.timings);
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getTimes();
    }, []);

    const handleTimeChange = (time) => {
        setTime(time);
        setDateTime(`${date} ${time}`);
    }

    const handleCancelBookingWindow = () => {
        setBooking(false);
        setDateTime(null);
        setTime(null);
        setDate(null);
        setPkg(1);
    }

    useEffect(() => {
        let availableHours = hours.map((hour, index) => {
            return hourNums[index];
        });

        let takenHours = times?.filter((time) => {
            return time[1] === date;
        });

        takenHours = takenHours?.map((time) => {
            return time[2];
        });

        availableHours = availableHours.filter((hour) => !takenHours?.includes(hour));

        let finalHours = [];

        if (Number(pkg) === 1) {
            for (let i = 0; i < availableHours.length; i++) {
                if (availableHours.includes(availableHours[i] + 1)) {
                    finalHours.push(availableHours[i]);
                }
            }
        } else if (Number(pkg) === 2 || Number(pkg) == 3) {
            for (let i = 0; i < availableHours.length; i++) {
                if (availableHours.includes(availableHours[i] + 1) && availableHours.includes(availableHours[i] + 2)) {
                    finalHours.push(availableHours[i]);
                }
            }
        } else if (Number(pkg) === 4) {
            for (let i = 0; i < availableHours.length; i++) {
                if (availableHours.includes(availableHours[i] + 1) && availableHours.includes(availableHours[i] + 2) && availableHours.includes(availableHours[i] + 3)) {
                    finalHours.push(availableHours[i]);
                }
            }
        } else if (Number(pkg) === 5) {
            for (let i = 0; i < availableHours.length; i++) {
                if (availableHours.includes(availableHours[i] + 1) && availableHours.includes(availableHours[i] + 2) && availableHours.includes(availableHours[i] + 3) && availableHours.includes(availableHours[i] + 4) && availableHours.includes(availableHours[i] + 5)) {
                    finalHours.push(availableHours[i]);
                }
            }
        }

        finalHours = finalHours.map((hour) => {
            return hours[hourNums.indexOf(hour)];
        });

        setCurrHours(finalHours);

    }, [date, pkg]);

    const handleBooking = async (e) => {
        e.preventDefault();

        if (!dateTime) {
            alert("Please select a time.");
            return;
        }

        setBooking(false);
        setLoading(true);
        const booking_info = JSON.stringify({
            name: e.target.elements.name.value,
            package: e.target.elements.package.value,
            vehicle_info: e.target.elements.vehicle_info.value,
            address: e.target.elements.address.value,
            city: e.target.elements.city.value,
            postal_code: e.target.elements.postal_code.value,
            email: e.target.elements.contact_email ? e.target.elements.contact_email.value : auth.email,
            phone_number: e.target.elements.phone_number.value,
            date_time: dateTime,
            comments: e.target.elements.comments.value
        })

        console.log(booking_info)

        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/bookings', { booking_info });

            if (response.data.status.email) {
                setAuth(response.data.status);
                localStorage.setItem("uo_user", JSON.stringify(response.data.status));
                alert("Successfully booked appointment.");
            } else {
                alert(response.data.status);
            }
        } catch (error) {
            alert("Failed to book appointment.");
            console.error(error);
        }
        setLoading(false);
        setDateTime(null);
        setTime(null);
        setDate(null);
        getTimes();
        e.target.reset();
    }

    const bookingForm = () => {
        if (auth && booking) {
            return (
                <form onSubmit={handleBooking} className='booking-form'>
                    <label>Name:</label>
                    <input required defaultValue={auth.name} name='name' type='text' placeholder='First Last' />

                    <label>Package:</label>
                    <select name='package' onChange={(e) => setPkg(e.target.value)}>
                        <option value={1} >Basic Wash 0.5h - 1h</option>
                        <option value={2} >Essentials 1h - 1.5h </option>
                        <option value={3} >Silver 1.5h - 2h</option>
                        <option value={4} >Gold 2h - 3h</option>
                        <option value={5} >Platinum 4h - 6h</option>
                    </select>

                    <label>Vehicle(s) Details:</label>
                    <input required name='vehicle_info' type='text' placeholder='Make/Model' />

                    <label>Address:</label>
                    <input required defaultValue={auth.address} name='address' type='text' placeholder='Street Address' />

                    <label>City:</label>
                    <input required defaultValue={auth.city} name='city' type='text' placeholder='City' />

                    <label>Postal Code:</label>
                    <input required defaultValue={auth.postal_code} name='postal_code' type='text' placeholder='Postal Code' />

                    <label>Phone Number:</label>
                    <input required defaultValue={auth.phone_num} name='phone_number' type='tel' placeholder="123-456-789" />

                    <label>Date:</label>
                    <input onChange={e => setDate(e.target.value)} required name='date' type='date' min={new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toLocaleDateString("en-CA", { timeZone: 'America/Toronto', year: 'numeric', month: '2-digit', day: '2-digit' })} />

                    <label>Choose a Time:</label>
                    <div className='time-choices' style={{ display: date && times ? "grid" : "none" }}>
                        {currHours.map((hour, index) => (
                            <p onClick={() => handleTimeChange(hourNums[index])} className={time === hourNums[index] ? 'time sel' : 'time'} key={index}>{hour}</p>))}
                    </div>

                    <label>Comments:</label>
                    <textarea name='comments'></textarea>

                    <div className='form-btns'>
                        <button type='button' onClick={handleCancelBookingWindow}>Cancel</button>
                        <button type='submit'>Confirm Booking</button>
                    </div>

                </form>
            )
        } else if (booking) {
            return (
                <form onSubmit={handleBooking} className='booking-form'>
                    <label>Name:</label>
                    <input required name='name' type='text' placeholder='First Last' />

                    <label>Package:</label>
                    <select name='package' onChange={(e) => setPkg(e.target.value)}>
                        <option value={1} >Basic Wash 0.5h - 1h</option>
                        <option value={2} >Essentials 1h - 1.5h </option>
                        <option value={3} >Silver 1.5h - 2h</option>
                        <option value={4} >Gold 2h - 3h</option>
                        <option value={5} >Platinum 4h - 6h</option>
                    </select>

                    <label>Vehicle(s) Details:</label>
                    <input required name='vehicle_info' type='text' placeholder='Make/Model' />

                    <label>Address:</label>
                    <input required name='address' type='text' placeholder='Street Address' />

                    <label>City:</label>
                    <input required name='city' type='text' placeholder='City' />

                    <label>Postal Code:</label>
                    <input required name='postal_code' type='text' placeholder='Postal Code' />

                    <label>Contact Email:</label>
                    <input required name='contact_email' type='email' placeholder='Email' />

                    <label>Phone Number:</label>
                    <input required name='phone_number' type='tel' placeholder="123-456-7890" />

                    <label>Date:</label>
                    <input onChange={e => setDate(e.target.value)} required name='date' type='date' min={new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toLocaleDateString("en-CA", { timeZone: 'America/Toronto', year: 'numeric', month: '2-digit', day: '2-digit' })} />

                    <label>Choose a Time:</label>
                    <div className='time-choices' style={{ display: date && times ? "grid" : "none" }}>
                        {currHours.map((hour, index) => (
                            <p onClick={() => handleTimeChange(hourNums[index])} className={time === hourNums[index] ? 'time sel' : 'time'} key={index}>{hour}</p>))}
                    </div>

                    <label>Comments:</label>
                    <textarea name='comments'></textarea>

                    <div className='form-btns'>
                        <button type='button' onClick={handleCancelBookingWindow}>Cancel</button>
                        <button type='submit'>Confirm Booking</button>
                    </div>

                    <label style={{ marginBottom: '10px', fontSize: '20px' }}>NOTE: You're currently not logged in, if you need to cancel this booking, please contact us direcly via email or phone.</label>

                </form>
            );
        }
    }

    return (
        <>

            <div className='loader-cont' style={{ display: loading ? 'flex' : 'none' }}>
                <h1>Loading...</h1>
            </div>
            <div className='booking-cont' style={{ display: booking ? 'flex' : 'none' }}>

                <div className='booking-window'>

                    <div className='booking-header'>
                        <h1>Schedule Appointment</h1>
                    </div>

                    <div className='booking-body'>
                        {bookingForm()}
                    </div>

                </div>

            </div>

            <div className="uo-cont">

                <button style={{ display: booking ? 'none' : 'flex' }} disabled={times ? false : true} className='book-btn' onClick={() => setBooking(true)}>
                    BOOK NOW
                </button>

                <div className='nav-cont'>

                    <div className='nav-ls'>
                        <a onClick={() => window.scrollTo(0, 0)} className='nav-btn'>Home</a>
                        <a href='/#packages' className='nav-btn'>Pricing</a>
                    </div>

                    <div className='nav-ct'>
                        <img className='logo' src={logo} />
                    </div>

                    <div className='nav-rs'>
                        <a href='/#contact' className='nav-btn'>Contact Us</a>
                        <a href={auth ? "/profile" : '/login'} className='nav-btn'>{auth ? 'View Profile' : 'Log In'}</a>
                    </div>

                </div>

                <div className={navActive ? 'mobile-nav active' : 'mobile-nav'}>
                    <img onClick={() => setNavActive(!navActive)} className='m-icn' src={navActive ? x : bars} alt='Menu' />
                    <div className='m-nav-ct'>
                        <img className='m-logo' src={logo} />
                    </div>
                    <div className='m-nav-opt'>
                        <a onClick={() => { window.scrollTo(0, 0); setNavActive(false); }} className='nav-btn'>Home</a>
                    </div>
                    <div className='m-nav-opt'>
                        <a onClick={() => setNavActive(false)} href='/#packages' className='nav-btn'>Pricing</a>
                    </div>
                    <div className='m-nav-opt'>
                        <a onClick={() => {setNavActive(false);window.scrollTo(0, document.body.scrollHeight);}} href='/#contact' className='nav-btn'>Contact Us</a>
                    </div>
                    <div className='m-nav-opt'>
                        <a onClick={() => setNavActive(false)} href={auth ? "/profile" : '/login'} className='nav-btn'>{auth ? 'View Profile' : 'Log In'}</a>
                    </div>
                </div>

                <div className='landing-pg' id='landing' style={{ filter: booking || loading ? 'blur(5px)' : 'none' }}>

                    <div className='slogan-cont'>
                        <h1 className='slogan'>WE COME TO YOU!</h1>
                    </div>

                    <div className='services-cont'>

                        <div className='service'>
                            <h3 className='service-title'>INSPECTION</h3>
                            <img className='service-img' src={inspect} />
                            <p className='service-txt' style={{ fontFamily: 'Arial', fontSize: '21px' }}>At Underground Oasis, we begin every service with a detailed inspection to customize and optimize your detailing experience. Ensuring a customized and cost-effective detailing experience.</p>
                        </div>

                        <div className='service'>
                            <h3 className='service-title'>WASH</h3>
                            <img className='service-img' src={wash} />
                            <p className='service-txt' style={{ fontFamily: 'Arial', fontSize: '21px' }}>We offer eco-friendly, advanced exterior washes that leave your car spotless, whether at your driveway or underground, using our mobile equipment.</p>
                        </div>

                        <div className='service'>
                            <h3 className='service-title'>INTERIOR & EXTERIOR</h3>
                            <img className='service-img' src={intext} />
                            <p className='service-txt' style={{ fontFamily: 'Arial', fontSize: '21px' }}>Enjoy comprehensive car care with our premium interior and exterior detailing, ensuring your vehicle is spotless and rejuvenated, delivered directly to you.</p>
                        </div>

                    </div>

                </div>

                <div className='package-details-page' id='packages'>
                    <div className='packages-details-title'>
                        <h1>Package Details</h1>
                    </div>
                    <div className='package-details-cont'>

                        <div className='package-details-div'>
                            <h2 style={{ display: 'flex' }}>Essentials</h2>
                            <div className='package-info'>
                                <h3>Exterior Hand Wash services, along with:</h3>
                                <br />
                                <p>- Dry Vacuuming: Ensures the interior is free from dirt, dust, and debris.</p>
                                <br />
                                <p>- Tire Dressing: Enhances tire appearance and provides a protective layer.</p>
                                <br />
                                <h3>Pricing:</h3>
                                <br />
                                <p>- Sedans: $50 - $60</p>
                                <br />
                                <p>- Trucks/larger Vehicles: $60 - $70</p>
                                <br />
                                <h3>Add-Ons:</h3>
                                <br />
                                <p>- Tire + Rim Removal Cleanup: $60+</p>
                                <br />
                                <p>- Engine Wash: $50+</p>
                            </div>
                        </div>

                        <div className='package-details-div'>
                            <h2 style={{ color: "#b8b8b8", display: 'flex' }}>Silver</h2>
                            <div style={{ borderColor: "#b8b8b8" }} className='package-info'>
                                <h3 style={{ color: "#b8b8b8" }}>Essential package services, along with:</h3>
                                <br />
                                <p style={{ color: "#b8b8b8" }}>- Rubber Mats Cleanup: Ensures mats are thoroughly cleaned.</p>
                                <br />
                                <p style={{ color: "#b8b8b8" }}>- Interior Wipe Down: Cleans and sanitizes interior surfaces.</p>
                                <br />
                                <p style={{ color: "#b8b8b8" }}>- Interior/Exterior Window Cleanup: Leaves windows spotless inside and out.</p>
                                <br />
                                <p style={{ color: "#b8b8b8" }}>- Interior Vacuum: Ensures the interior is free from dirt and debris.</p>
                                <br />
                                <h3 style={{ color: "#b8b8b8" }}>Pricing:</h3>
                                <br />
                                <p style={{ color: "#b8b8b8" }}>- Starts at $130.</p>
                            </div>
                        </div>

                        <div className='package-details-div'>
                            <h2 style={{ color: "#ffd700", display: 'flex' }}>Gold</h2>
                            <div style={{ borderColor: "#ffd700" }} className='package-info'>
                                <h3 style={{ color: "#ffd700" }}>Silver package services, along with:</h3>
                                <br />
                                <p style={{ color: "#ffd700" }}>- Exterior Wash & Wax</p>
                                <br />
                                <p style={{ color: "#ffd700" }}>- Clean Rims/Tires/Wheel Wells</p>
                                <br />
                                <p style={{ color: "#ffd700" }}> -Clean Windows Inside & Out</p>
                                <br />
                                <p style={{ color: "#ffd700" }}>- Remove Seats and Interior Vacuum, Interior Shampoo, De-Salting</p>
                                <br />
                                <p style={{ color: "#ffd700" }}>- De-Odourizing, Interior Wipe Down, Engine Shampoo, Hand Wax</p>
                                <br />
                                <h3 style={{ color: "#ffd700" }}>Pricing:</h3>
                                <br />
                                <p style={{ color: "#ffd700" }}>- Starts at $250</p>
                                <img className='best-value' style={{ width: "150px", height: "150px", position: "absolute", right: "0px", bottom: "0px" }} src={value} alt='Best Value' />
                            </div>
                        </div>

                        <div className='package-details-div'>
                            <h2 style={{ color: "#87CEEB", display: 'flex' }}>Platinum</h2>
                            <div style={{ borderColor: "#87CEEB" }} className='package-info'>
                                <h3 style={{ color: "#87CEEB" }}>Gold package services, along with:</h3>
                                <br />
                                <p style={{ color: "#87CEEB" }}>- 1 Year Ceramic Coating: Provides long-lasting protection and shine.</p>
                                <br />
                                <p style={{ color: "#87CEEB" }}>- Clay Bar Treatment: Removes contaminants for a smooth surface.</p>
                                <br />
                                <p style={{ color: "#87CEEB" }}>- High-Speed Polish: Enhances the vehicle’s gloss and finish.</p>
                                <br />
                                <h3 style={{ color: "#87CEEB" }}>Pricing:</h3>
                                <br />
                                <p style={{ color: "#87CEEB" }}>- Starts at $450</p>
                            </div>
                        </div>
                    </div>
                    <div className='package-footer'>
                        <h2>Basic Exterior Hand Wash: $30 - $55</h2>
                        <p>Cleaning for vehicle exterior, rims/tires, exterior windows and wiped jams.</p>
                    </div>
                </div>

                {/* <div className='packages-pg' id='packages' style={{ filter: booking || loading ? 'blur(5px)' : 'none' }}>

                    <div className='package-header-cont'>
                        <h1 className='package-header'>ASK US ABOUT OUR PACKAGES!</h1>
                        <a href='/packages'>View Full Details Here</a>
                    </div> 
                    
                    <div className='packages-cont'>

                        <div className='package' style={{ borderColor: "white" }}>
                            <h3 className='package-title' style={{ color: "white", borderColor: "white" }}>Essentials: $50</h3>
                            <p className='package-txt' style={{ color: "white" }}>
                                - Deep clean wash with hand dry
                                <br /> <br />
                                - Tire dressing for a polished look
                                <br /> <br />
                                - Interior dry vacuuming
                                <br /> <br />
                                - Door jamb and window cleaning
                            </p>
                        </div>

                        <div className='package' style={{ borderColor: "#b8b8b8" }}>
                            <h3 className='package-title' style={{ color: "#b8b8b8", borderColor: "#b8b8b8" }}>Silver: $120</h3>
                            <p className='package-txt' style={{ color: "#b8b8b8" }}>
                                - Everything in Essential Package
                                <br /> <br />
                                - Exterior wax for lasting shine
                                <br /> <br />
                                - Interior shampooing of mats
                                <br /> <br />
                                - Thorough interior and window cleaning
                                <br /> <br />
                                - Engine degreasing
                            </p>
                        </div>

                        <div className='package' style={{ borderColor: "#ffd700" }}>
                            <h3 className='package-title' style={{ color: "#ffd700", borderColor: "#ffd700" }}>Gold: $180</h3>
                            <p className='package-txt' style={{ color: "#ffd700" }}>
                                - Deep interior cleaning
                                <br /> <br />
                                - Seat removal, Carpet, mat, trunk shampoo
                                <br /> <br />
                                - De-salting + deodorizing for fresh air
                                <br /> <br />
                                - Engine shampoo + hand wax
                            </p>
                        </div>

                        <div className='package' style={{ borderColor: "#87CEEB" }}>
                            <h3 className='package-title' style={{ color: "#87CEEB", borderColor: "#87CEEB" }}>Platinum: $350</h3>
                            <p className='package-txt' style={{ color: "#87CEEB" }}>
                                - Everything in Gold Package
                                <br /> <br />
                                - Hand wax with year-long ceramic coating
                                <br /> <br />
                                - Deep rim cleaning with tire removal
                                <br /> <br />
                                - High-speed polishing
                                <br /> <br />
                                - Engine Degreasing
                            </p>
                        </div>

                    </div>

                    <div className='before-after-cont'>

                        <div className='ba-header'>
                            <h3 className='ba-header-txt'>BEFORE / AFTER</h3>
                        </div>

                        <div className='ba-cont'>

                            <div className='ba-ls'>

                                <div className='video-cont'>
                                    <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={baImg} autoPlay loop muted />
                                </div>

                                <div className='apointment-choices'>

                                    <div className='choices-row'>

                                        <div className='choice'>
                                            <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={quad1} onClick={() => setBaImg(quad1)} />
                                        </div>

                                        <div className='choice'>
                                            <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={quad2} onClick={() => setBaImg(quad2)} />
                                        </div>

                                    </div>

                                    <div className='choices-row'>

                                        <div className='choice'>
                                            <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={quad3} onClick={() => setBaImg(quad3)} />
                                        </div>

                                        <div className='choice'>
                                            <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={quad4} onClick={() => setBaImg(quad4)} />
                                        </div>

                                    </div>

                                </div>

                            </div>

                            <div className='ba-rs'>

                                <div className='details-cont'>
                                    <p className='det-lg' style={{ fontSize: "20px" }}>HOURS OF OPERATION:</p>
                                    <p className='det-sm' style={{ fontSize: "15px" }}>8am - 8pm</p>
                                    <p className='det-sm' style={{ fontSize: "15px" }}>Monday - Friday</p>
                                    <p className='det-lg' style={{ fontSize: "20px" }}>CONTACT INFORMATION:</p>
                                    <p className='det-sm' style={{ fontSize: "15px" }}><span>Phone #:</span> 647-491-8873</p>
                                    <p className='det-sm' style={{ fontSize: "15px" }}><span>Email:</span> ali@undergroundoasis.ca</p>
                                </div>

                                <button disabled={times ? false : true} className='book-btn' onClick={() => setBooking(true)}>
                                    BOOK YOUR APPOINTMENT
                                </button>

                            </div>

                        </div>

                    </div>

                </div> */}

                <div className='contact-pg' id='contact' style={{ filter: booking || loading ? "blur(5px)" : "none" }}>

                    <div className='contact-cont'>

                        <div className='contact-ls' id='login'>

                            <h3 className='contact-header'>Contact Us</h3>

                            <form className='auth-form'>
                                <label className='det-lg' style={{ fontSize: "25px" }}>HOURS OF OPERATION:</label>
                                <label className='det-sm' style={{ fontSize: "20px" }}>8am - 8pm</label>
                                <label className='det-sm' style={{ fontSize: "20px" }}>Monday - Friday</label>
                                <label className='det-lg' style={{ fontSize: "25px" }}>CONTACT INFORMATION:</label>
                                <label className='det-sm' style={{ fontSize: "20px" }}>Phone #: 647-491-8873</label>
                                <label className='det-sm' style={{ fontSize: "20px" }}>Email: ali@undergroundoasis.ca</label>
                            </form>

                        </div>

                        <div className='contact-rs'>
                            <h1 className='mission-header'>Our Mission:</h1>

                            <div className='mission-cont'>
                                <p>
                                    At Underground Oasis, our mission is to redefine convenience and excellence in car care. We bring the oasis to your doorstep, offering meticulous mobile detailing services that transcend traditional limitations. With a commitment to sustainability and innovation, we deliver premium cleaning solutions without compromise, whether you're parked in your driveway or exploring the urban jungle. Our dedication to quality, convenience, and environmental responsibility ensures that every customer experience with Underground Oasis is a refreshing oasis of satisfaction and delight.
                                </p>
                            </div>

                        </div>

                    </div>

                    <div className='footer'>
                        <h3 className='footer-txt'>Register and view your personalized gallery!</h3>
                    </div>

                </div>

            </div>
        </>
    );
}

export default Landing;