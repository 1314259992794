import React from 'react';
import "./packages.css"
import value from "./value.png"
import { useNavigate } from 'react-router-dom';

function Packages() {
    const navigate = useNavigate();

    return (
        <div className='package-details-page'>
            <div className='packages-details-title'>
                <h1>Package Details</h1>
            </div>
            <div className='back-btn'>
                <h1 onClick={() => navigate('/')}>{"<"}</h1>
            </div>
            <div className='package-details-cont'>
                <div>
                    <h2>Essentials</h2>
                    <div className='package-info'>
                        <h3>Exterior Hand Wash services, along with:</h3>
                        <br />
                        <p>- Dry Vacuuming: Ensures the interior is free from dirt, dust, and debris.</p>
                        <br />
                        <p>- Tire Dressing: Enhances tire appearance and provides a protective layer.</p>
                        <br />
                        <h3>Pricing:</h3>
                        <br />
                        <p>- Sedans: $50 - $60</p>
                        <br />
                        <p>- Trucks/larger Vehicles: $60 - $70</p>
                        <br />
                        <h3>Add-Ons:</h3>
                        <br />
                        <p>- Tire + Rim Removal Cleanup: $60+</p>
                        <br />
                        <p>- Engine Wash: $50+</p>
                    </div>
                </div>

                <div>
                    <h2 style={{ color: "#b8b8b8" }}>Silver</h2>
                    <div style={{borderColor: "#b8b8b8"}} className='package-info'>
                        <h3 style={{ color: "#b8b8b8" }}>Essential package services, along with:</h3>
                        <br />
                        <p style={{ color: "#b8b8b8" }}>- Rubber Mats Cleanup: Ensures mats are thoroughly cleaned.</p>
                        <br />
                        <p style={{ color: "#b8b8b8" }}>- Interior Wipe Down: Cleans and sanitizes interior surfaces.</p>
                        <br />
                        <p style={{ color: "#b8b8b8" }}>- Interior/Exterior Window Cleanup: Leaves windows spotless inside and out.</p>
                        <br />
                        <p style={{ color: "#b8b8b8" }}>- Interior Vacuum: Ensures the interior is free from dirt and debris.</p>
                        <br />
                        <h3 style={{ color: "#b8b8b8" }}>Pricing:</h3>
                        <br />
                        <p style={{ color: "#b8b8b8" }}>- Starts at $130.</p>
                    </div>
                </div>

                <div>
                    <h2 style={{ color: "#ffd700" }}>Gold</h2>
                    <div style={{ borderColor: "#ffd700" }} className='package-info'>
                        <h3 style={{ color: "#ffd700" }}>Silver package services, along with:</h3>
                        <br />
                        <p style={{ color: "#ffd700" }}>- Exterior Wash & Wax</p>
                        <br />
                        <p style={{ color: "#ffd700" }}>- Clean Rims/Tires/Wheel Wells</p>
                        <br />
                        <p style={{ color: "#ffd700" }}> -Clean Windows Inside & Out</p>
                        <br />
                        <p style={{ color: "#ffd700" }}>- Remove Seats and Interior Vacuum, Interior Shampoo, De-Salting</p>
                        <br />
                        <p style={{ color: "#ffd700" }}>- De-Odourizing, Interior Wipe Down, Engine Shampoo, Hand Wax</p>
                        <br />
                        <h3 style={{ color: "#ffd700" }}>Pricing:</h3>
                        <br />
                        <p style={{ color: "#ffd700" }}>- Starts at $250</p>
                        <img className='best-value' style={{ width: "150px", height: "150px", position: "absolute", right: "0px", bottom: "0px" }} src={value} alt='Best Value' />
                    </div>
                </div>

                <div>
                    <h2 style={{ color: "#87CEEB" }}>Platinum</h2>
                    <div style={{borderColor: "#87CEEB"}} className='package-info'>
                        <h3 style={{ color: "#87CEEB" }}>Gold package services, along with:</h3>
                        <br />
                        <p style={{ color: "#87CEEB" }}>- 1 Year Ceramic Coating: Provides long-lasting protection and shine.</p>
                        <br />
                        <p style={{ color: "#87CEEB" }}>- Clay Bar Treatment: Removes contaminants for a smooth surface.</p>
                        <br />
                        <p style={{ color: "#87CEEB" }}>- High-Speed Polish: Enhances the vehicle’s gloss and finish.</p>
                        <br />
                        <h3 style={{ color: "#87CEEB" }}>Pricing:</h3>
                        <br />
                        <p style={{ color: "#87CEEB" }}>- Starts at $450</p>
                    </div>
                </div>
            </div>
            <div className='package-footer'>
                <h2>Basic Exterior Hand Wash: $30 - $55</h2>
                <p>Cleaning for vehicle exterior, rims/tires, exterior windows and wiped jams.</p>
            </div>
        </div>
    );
}

export default Packages;