import './App.css';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Profile from './profile.js';
import Landing from './landing.js';
import Admin from './admin.js';
import Packages from './packages.js';
import Login from './login.js';

function App() {
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("uo_user") !== null) {
      setAuth(JSON.parse(localStorage.getItem("uo_user")));
    }
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route exact path='/profile' element={<Profile auth={auth} setAuth={setAuth} />} />
          <Route exact path='/' element={<Landing auth={auth} setAuth={setAuth} />} />
          <Route exact path='/admin' element={<Admin auth={auth} />} />
          <Route exact path='/packages' element={<Packages />} />
          <Route exact path='/login' element={<Login setAuth={setAuth} />} />
          {/* <Route exact path='/admin' element={null} /> */}
        </Routes>
      </Router>
    </>
  );
}

export default App;

// {"address":null,"appointments":[],"city":null,"email":"ali@undergroundoasis.ca","id":1,"name":null,"phone_num":null,"postal_code":null}