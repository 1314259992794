import { useState } from 'react';
import './login.css';
import logo from './uo_logo.png';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Login({ setAuth }) {
    const [authMode, setAuthMode] = useState("login");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/auth/login', {
                user_info: JSON.stringify({
                    email: e.target.elements.email.value,
                    password: e.target.elements.password.value
                })
            });

            if (response.data.status.email) {
                setAuth(response.data.status);
                localStorage.setItem("uo_user", JSON.stringify(response.data.status));
                alert("Login Successful!")
                navigate('/');
            } else {
                alert(response.data.status);
            }
        } catch (error) {
            alert("Failed to login.");
            console.error(error);
        }
        setLoading(false);
        e.target.reset();
    }

    const handleSignup = async (e) => {

        e.preventDefault();

        if (e.target.elements.password.value !== e.target.elements.confirm.value) {
            alert("Passwords do not match");
            return;
        }

        setLoading(true);
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/auth/signup', {
                user_info: JSON.stringify({
                    email: e.target.elements.email.value,
                    password: e.target.elements.password.value
                })
            });
            alert(response.data.status);
            setAuthMode("login");
        } catch (error) {
            console.error(error);
        }
        setLoading(false);

        e.target.reset();
    }

    return (
        <div className='login-page'>
            <div className='login-cont'>
                <h1 className='back-btn' onClick={() => navigate('/')}>{"<"}</h1>
                <img src={logo} />
                <h1 className='login-txt'>Welcome!</h1>
                {
                    authMode === "login" ?
                        <form onSubmit={handleLogin}>
                            <input required name='email' type="text" placeholder="Email" />
                            <input required name='password' type="password" placeholder="Password" />
                            <button type="submit" disabled={loading}>Log In</button>
                            <p>Don't have an account? <span onClick={loading ? null : () => setAuthMode("signup")}>Sign Up</span></p>
                        </form>
                        :
                        <form onSubmit={handleSignup}>
                            <input required name='email' type="text" placeholder="Email" />
                            <input required name='password' type="password" placeholder="Password" />
                            <input required name='confirm' type="password" placeholder="Confirm Password" />
                            <button type="submit" disabled={loading}>Sign Up</button>
                            <p>Already have an account? <span onClick={loading ? null : () => setAuthMode("login")}>Log In</span></p>
                        </form>
                }
            </div>
        </div>
    );
}

export default Login;